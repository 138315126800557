import utils from '@/utils/utils'
import ipcheck from '@/api/ipcheck'
/**
 * Created by jiachenpan on 16/11/18.
 */

export function isValidUsername (str) {
  if (!str) return false
  if (str.length > 100) return false
  return true
  // const validMap = ['admin', 'editor']
  // return validMap.indexOf(str.trim()) >= 0
}

export function isExternal (path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function isEmail (email) {
  // eslint-disable-next-line no-useless-escape
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return reg.test(email)
}

export function isValidEmail (email) {
  // 使用正则表达式检测电子邮件地址是否合法
  const emailRegex = /^[\w.-]+@[a-z0-9.-]+\.[a-z]{2,}$/i
  return emailRegex.test(email)
}
/**
 * @param {string} str
 * @returns {boolean}
 */
export function isTelephone (str) {
  const reg = /^1[3-9]\d{9}$/
  return reg.test(str)
}

/**
 * 验证邮箱
 * @param rule
 * @param value
 * @param callback
 * @returns {*}
 */
export const validEmail = (rule, value, callback) => {
  if (utils.isEmpty(value) || isEmail(value)) {
    callback()
  } else {
    return callback(new Error('邮箱地址格式不正确'))
  }
}

export const validIpv4 = (rule, value, callback) => {
  if (ipcheck.solve(value) === 'IPv4') {
    callback()
  } else {
    return callback(new Error('邮箱地址格式不正确'))
  }
}
