// import request from '../../api/request'

const state = {
  activeTab: 'tab1',
  AddDomainForm: {
    uid: null,
    group: null,
    domain: ''
  },
  DelDomainForm: {},
  AddRecordForm: {
    clear: false,
    records: []
  },
  ModifyRecordForm: {},
  OptRecordForm: {},
  ImportRecordForm: {
    zones: '',
    delimiter: ' '
  }
}

const getters = {}

const mutations = {
  SET_ACTIVE_TAB (state, value) {
    state.activeTab = value
  }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
