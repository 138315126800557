import request from '../../api/request'

const state = {
  groupList: [],
  groupNames: [],
  groupForm: {},
  groupTotal: 0,

  // filter
  filter: {
    keyword: ''
  },
  // pagination
  pageNum: 1,
  pageSize: 10
}

const getters = {}

const mutations = {
  SET_GROUP_FORM (state, value) {
    state.groupForm = value
  },
  SET_GROUP_LIST (state, value) {
    state.groupList = value
  },
  SET_GROUP_NAMES (state, value) {
    state.groupNames = value
  },
  SET_GROUP_TOTAL (state, value) {
    state.groupTotal = value
  },
  SET_PAGE_NUM (state, value) {
    state.pageNum = value
  },
  SET_PAGE_SIZE (state, value) {
    state.pageSize = value
  }
}

const actions = {
  getGroupList ({ state, commit }) {
    request.get('/GetDomainGroupList', {
      page_num: state.pageNum,
      page_size: state.pageSize,
      keyword: state.keyword || undefined
    }).then(response => {
      if (response.data.status === 'ok' && response.data.data) {
        let data = (response.data.data.list || []).map(d => { return d })
        data.push({ _id: '000000000000000000000000', name: '默认' })
        commit('SET_GROUP_LIST', data)
      } else {
        commit('SET_GROUP_LIST', [{ _id: '000000000000000000000000', name: '默认' }])
      }
    })
  },
  getGroupNames ({ state, commit }) {
    request.get('/domain/GetDomainGroupNames', {})
      .then(response => {
        if (response.data.status === 'ok' && response.data.data) {
          let data = response.data.data.map(d => { return d })
          data.push({ _id: '000000000000000000000000', name: '默认' })
          commit('SET_GROUP_NAMES', data)
        } else {
          commit('SET_GROUP_NAMES', [{ _id: '000000000000000000000000', name: '默认' }])
        }
      })
  },
  editGroup ({ state, dispatch }) {
    request.post(`/group/${state.groupForm._id}`, state.groupForm)
      .then(() => {
        dispatch('getGroupList')
      })
  },
  AddDomainGroup ({ state, dispatch }) {
    return request.post(`/AddDomainGroup`, { gname: state.groupForm.gname })
    // .then(() => {
    //   dispatch('getGroupList')
    //   // dispatch('getGroupNames')
    // })
  },
  deleteGroup ({ state, dispatch }, id) {
    request.delete(`/group/${id}`)
      .then(() => {
        dispatch('getGroupList')
      })
  },
  getGroupData ({ state, commit }, id) {
    request.get(`/group/${id}`)
      .then(response => {
        commit('SET_GROUP_FORM', response.data.data)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
