<template>
  <el-tag :type="type" class="LogState-tag" effect="plain">
    {{label}}
  </el-tag>
</template>

<script>
export default {
  name: 'DomainExpStateTag',
  props: {
    state: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      Dict: {
        0: { label: '在用', type: 'success' },
        1: { label: '到期', type: 'danger' }
      }
    }
  },
  computed: {
    type () {
      const s = this.Dict[this.state]
      if (s) {
        return s.type
      }
      return ''
    },
    label () {
      const s = this.Dict[this.state]
      if (s) {
        return s.label
      }
      return this.Dict[0]
    }
  }
}
</script>

<style scoped>

</style>
