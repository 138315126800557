import Layout from '@/views/layout/Layout.vue'

const state = {
  routes: [],
  addRoutes: []
}

const asyncRoutes = [
  // dns Pages
  {
    path: '/home',
    component: Layout,
    meta: {
      roles: ['admin', 'normal']
    },
    children: [
      {
        path: '',
        // component: () => import('../views/home/test.vue'),
        component: () => import('@/views/home/Home'),
        meta: {
          title: 'Home',
          icon: 'fa fa-home',
          roles: ['admin', 'normal']
        }
      }
    ]
  },
  {
    path: '/domain',
    component: Layout,
    meta: {
      title: '解析管理',
      icon: 'fa fa-bug',
      roles: ['admin', 'normal']
    },
    children: [
      {
        path: 'domainResol',
        name: 'domainResol',
        component: () => import('@/views/domain/domainResol'),
        meta: {
          title: '域名解析',
          icon: 'fa fa-list-alt',
          roles: ['admin', 'normal']
        }
      },
      {
        path: '/domain/:domain/:id',
        name: 'Records',
        component: () => import('@/views/domain/record'),
        meta: {
          title: '解析记录',
          icon: 'fa fa-cog',
          roles: ['admin', 'normal']
        },
        hidden: true
      },
      {
        path: 'batchdomain',
        name: 'batchdomain',
        component: () => import('@/views/domain/BatchOperations.vue'),
        meta: {
          title: '批量操作',
          icon: 'fa fa-list-alt',
          roles: ['admin', 'normal']
        }
      },
      {
        path: 'domainptr',
        name: 'domainptr',
        component: () => import('@/views/domain/ptr'),
        meta: {
          title: '反向解析',
          icon: 'fa fa-table',
          roles: ['admin', 'normal']
        }
      },
      {
        path: 'nsgroup',
        name: 'nsgroup',
        component: () => import('@/views/domain/ns'),
        meta: {
          title: 'NS分组',
          icon: 'fa fa-institution',
          roles: ['admin']
        }
      }
    ]
  },
  {
    path: '/ForwardMan',
    component: Layout,
    meta: {
      title: '转发管理',
      icon: 'fa fa-facebook-square',
      roles: ['admin']
    },
    children: [
      {
        path: 'cfw',
        name: 'cfw',
        component: () => import('@/views/forward/cforward'),
        meta: {
          title: '条件转发',
          icon: 'fa fa-list-alt',
          roles: ['admin']
        }
      },
      {
        path: 'cfwset',
        name: 'cfwset',
        component: () => import('@/views/forward/cfwset'),
        meta: {
          title: '转发设置',
          icon: 'fa fa-circle-o',
          roles: ['admin']
        }
      }
    ]
  },
  {
    path: '/nodes',
    component: Layout,
    meta: {
      title: '节点管理',
      icon: 'fa fa-server',
      roles: ['admin']
    },
    children: [
      {
        path: 'NodeList',
        name: 'NodeList',
        component: () => import('@/views/node/NodeList'),
        meta: {
          title: 'Nodes',
          icon: 'fa fa-server',
          roles: ['admin']
        }
      },
      {
        path: ':id',
        name: 'NodeDetail',
        component: () => import('@/views/node/NodeDetail'),
        meta: {
          title: 'Node Detail',
          icon: 'fa fa-circle-o',
          roles: ['admin']
        },
        hidden: true
      }
    ]
  },
  {
    path: '/MonitorMan',
    component: Layout,
    meta: {
      title: '监控管理',
      icon: 'fa  fa-camera-retro',
      roles: ['admin', 'normal']
    },
    children: [
      {
        path: 'monitorList',
        name: 'monitorList',
        component: () => import('@/views/monitor/monitor.vue'),
        meta: {
          title: '监控列表',
          icon: 'fa fa-list-alt',
          roles: ['admin', 'normal']
        }
      },
      {
        path: 'monitorRec',
        name: 'monitorRec',
        component: () => import('@/views/monitor/monitorlog.vue'),
        meta: {
          title: '监控记录',
          icon: 'fa fa-sticky-note-o',
          roles: ['admin', 'normal']
        }
      }
    ]
  },
  {
    path: '/queryTotal',
    component: Layout,
    meta: {
      title: '查询统计',
      icon: 'fa fa-table',
      roles: ['admin', 'normal']
    },
    children: [
      {
        path: 'monitorTraffic',
        name: 'monitorTraffic',
        component: () => import('@/views/home/DnsQueryHome.vue'),
        meta: {
          title: '查询流量',
          icon: 'fa fa-stack-overflow',
          roles: ['admin', 'normal']
        }
      },
      {
        path: 'rankingList',
        name: 'rankingList ',
        component: () => import('@/views/query/ranking.vue'),
        meta: {
          title: '排行统计',
          icon: 'fa fa-sort-alpha-desc',
          roles: ['admin']
        }
      },
      {
        path: 'newRecord',
        name: 'newRecord ',
        component: () => import('@/views/query/querylog.vue'),
        meta: {
          title: '最新记录',
          icon: 'fa fa-send-o',
          roles: ['admin']
        }
      }
    ]
  },
  {
    path: '/productManage',
    component: Layout,
    meta: {
      title: '套餐管理',
      icon: 'fa fa-subway',
      roles: ['admin', 'normal']
    },
    children: [
      {
        path: 'productList',
        name: 'productList',
        component: () => import('@/views/product/product'),
        meta: {
          title: '套餐列表',
          icon: 'fa fa-th-list',
          roles: ['admin', 'normal']
        }
      },
      {
        path: 'serverOpenRec',
        name: 'serverOpenRec ',
        component: () => import('@/views/product/prolog.vue'),
        meta: {
          title: '开通记录',
          icon: 'fa fa-folder-open',
          roles: ['admin', 'normal']
        }
      }
    ]
  },
  {
    path: '/users',
    component: Layout,
    meta: {
      title: '用户管理',
      icon: 'fa fa-users',
      roles: ['admin', 'normal']
    },
    children: [
      {
        path: 'UserList',
        name: 'UserList',
        component: () => import('@/views/usermange/UserList.vue'),
        meta: {
          title: 'Users',
          icon: 'fa fa-users',
          roles: ['admin']
        }
      },
      {
        path: 'UserRechargeRec',
        name: 'UserRechargeRec',
        component: () => import('@/views/usermange/paylog.vue'),
        meta: {
          title: '充扣记录',
          icon: 'fa fa-users',
          roles: ['admin', 'normal']
        }
      },
      {
        path: 'UserChangePass',
        name: 'UserChangePass',
        component: () => import('@/views/usermange/modifypass.vue'),
        meta: {
          title: '修改密码',
          icon: 'fa fa-cc',
          roles: ['admin', 'normal']
        }
      }
    ]
  },
  {
    path: '/LogMan',
    component: Layout,
    meta: {
      title: '日志管理',
      icon: 'fa  fa-camera-retro',
      roles: ['admin']
    },
    children: [
      {
        path: 'OperateLog',
        name: 'OperateLog',
        component: () => import('@/views/log/operate.vue'),
        meta: {
          title: '操作日志',
          icon: 'fa fa-brands',
          roles: ['admin']
        }
      },
      {
        path: 'loginLog',
        name: 'loginLog',
        component: () => import('@/views/log/login.vue'),
        meta: {
          title: '登录日志',
          icon: 'fa fa-arrow-right-to-bracket',
          roles: ['admin']
        }
      },
      {
        path: 'clean',
        name: 'clean',
        component: () => import('@/views/log/clean.vue'),
        meta: {
          title: '日志清除',
          icon: 'fa fa-brands',
          roles: ['admin']
        }
      }

    ]
  },
  {
    path: '/setting',
    component: Layout,
    meta: {
      title: '系统设置',
      icon: 'fa fa-gear',
      roles: ['admin', 'normal']
    },
    children: [
      {
        path: 'siteSet',
        name: 'siteSet',
        component: () => import('@/views/set/siteSet.vue'),
        meta: {
          title: '后台设置',
          icon: 'fa fa-code',
          roles: ['admin']
        }
      },
      {
        path: 'dnsSetting',
        name: 'dnsSetting',
        component: () => import('@/views/set/dnsset.vue'),
        meta: {
          title: 'DNS设置',
          icon: 'fa fa-desktop',
          roles: ['admin']
        }
      },
      {
        path: 'SystemSet',
        name: 'SystemSet',
        component: () => import('@/views/set/systemset.vue'),
        meta: {
          title: '系统设置',
          icon: 'fa fa-gear',
          roles: ['admin']
        }
      },
      // 老版本
      // {
      //   path: '',
      //   name: 'Setting',
      //   component: () => import('@/views/setting/Setting'),
      //   meta: {
      //     title: '系统设置2',
      //     icon: 'fa fa-gear',
      //     roles: ['admin']
      //   }
      // },
      // {
      //   path: 'paySetting',
      //   name: 'paySetting',
      //   component: () => import('@/views/setting/Setting'),
      //   meta: {
      //     title: '支付设置',
      //     icon: 'fa fa-paypal'
      //   }
      // },
      {
        path: 'emailSetting',
        name: 'emailSetting',
        component: () => import('@/views/set/email.vue'),
        meta: {
          title: '邮箱设置',
          icon: 'fa fa-paypal',
          roles: ['admin']
        }
      },
      // {
      //   path: '',
      //   name: 'smsSetting',
      //   component: () => import('../views/setting/Setting'),
      //   meta: {
      //     title: '短信设置',
      //     icon: 'fa  fa-sticky-note'
      //   }
      // },
      {
        path: 'httpdnsSetting',
        name: 'httpdnsSetting',
        component: () => import('@/views/set/httpdns.vue'),
        meta: {
          title: 'HttpDns',
          icon: 'fa fa-file-text-o',
          roles: ['admin']
        }
      },
      {
        path: 'ukey',
        name: 'ukey',
        component: () => import('@/views/set/ukey.vue'),
        meta: {
          title: 'API设置',
          icon: 'fa  fa-user-md',
          roles: ['admin', 'normal']
        }
      }
    ]
  },
  { path: '*', redirect: '/404', hidden: true }
]

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = routes
  }
}

const actions = {
  generateRoutes ({ commit }, roles) {
    function hasPermission (roles, route) {
      if (route.meta && route.meta.roles) {
        return roles.some(role => route.meta.roles.includes(role))
      } else {
        return true
      }
    }

    function filterAsyncRoutes (routes, roles) {
      const res = []

      routes.forEach(route => {
        const tmp = { ...route }
        // if (hasPermission(roles, tmp) && !tmp.hidden) { // Check for hidden:true
        if (hasPermission(roles, tmp)) {
          if (tmp.children) {
            tmp.children = filterAsyncRoutes(tmp.children, roles)
          }
          res.push(tmp)
        }
      })

      return res
    }

    return new Promise(resolve => {
      let accessedRoutes
      // 根据角色生成动态路由
      accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)

      // 例如: if (roles.includes('admin')) { accessedRoutes = asyncRoutes || [] }
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
