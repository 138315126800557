
import Vue from 'vue'
import XEUtils from 'xe-utils'

import {
  // 核心
  VXETable,

  // 功能模块
  Icon,
  Filter,
  Menu,
  Edit,
  Export,
  Keyboard,
  Validator,
  Header,
  Footer,

  // 可选组件
  Column,
  Colgroup,
  Grid,
  Toolbar,
  Pager,
  Checkbox,
  CheckboxGroup,
  Radio,
  RadioGroup,
  RadioButton,
  Input,
  Textarea,
  Button,
  Modal,
  Tooltip,
  Form,
  FormItem,
  Select,
  Optgroup,
  Option,
  Switch,
  List,
  Pulldown,

  // 表格
  Table
} from 'vxe-table'
import zhCN from 'vxe-table/lib/locale/lang/zh-CN'
import FilterExcel from './FilterExcel.vue'

Vue.component(FilterExcel.name, FilterExcel)
// 按需加载的方式默认是不带国际化的，自定义国际化需要自行解析占位符 '{0}'，例如：
VXETable.setup({
  keepSource: true,
  zIndex: 1000, // 全局 zIndex 起始值，如果项目的的 z-index 样式值过大时就需要跟随设置更大，避免被遮挡
  i18n: (key, args) => XEUtils.toFormatString(XEUtils.get(zhCN, key), args),
  grid: {
    pager: {
      // size: null,
      // autoHidden: false,
      // perfect: true,
      pageSize: 1000,
      // pagerCount: 7,
      pageSizes: [10, 15, 20, 50, 100, 1000, 5000]
      // layouts: ['PrevJump', 'PrevPage', 'Jump', 'PageCount', 'NextPage', 'NextJump', 'Sizes', 'Total']
    },
    toolbar: {
      // eslint-disable-next-line no-undef
      // refresh: { query: this.query } // 直接修改默认reload,这样让刷新可以保持排序及过虑条件等
    }
  }
})

// 表格功能
Vue.use(Header)
  .use(Footer)
  .use(Icon)
  .use(Filter)
  .use(Edit)
  .use(Menu)
  .use(Export)
  .use(Keyboard)
  .use(Validator)

  // 可选组件
  .use(Column)
  .use(Colgroup)
  .use(Grid)
  .use(Toolbar)
  .use(Pager)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Radio)
  .use(RadioGroup)
  .use(RadioButton)
  .use(Input)
  .use(Textarea)
  .use(Button)
  .use(Modal)
  .use(Tooltip)
  .use(Form)
  .use(FormItem)
  .use(Select)
  .use(Optgroup)
  .use(Option)
  .use(Switch)
  .use(List)
  .use(Pulldown)

  // 安装表格
  .use(Table)

// 给 vue 实例挂载内部对象，例如：
Vue.prototype.$XModal = VXETable.modal
Vue.prototype.$XPrint = VXETable.print
Vue.prototype.$XSaveFile = VXETable.saveFile
Vue.prototype.$XReadFile = VXETable.readFile

// // 创建一个简单的工具栏-按钮渲染
// VXETable.renderer.add('ToolbarButtonAllRowExpand', {
//   renderToolbarButton (h, renderOpts, params) {
//     const { events } = renderOpts
//     const { button } = params
//     return [
//       <vxe-button icon='fa fa-chevron-down' status='success' onClick={ e => { events.click(button) } }>全部展开</vxe-button>
//     ]
//   }
// })
//
// // 创建一个简单的工具栏-按钮渲染
// VXETable.renderer.add('ToolbarButtonClearRowExpand', {
//   renderToolbarButton (h, renderOpts, params) {
//     const { events } = renderOpts
//     const { button } = params
//     return [
//       <vxe-button icon='fa fa-chevron-right' status='warning' onClick={ e => { events.click(button) } }>全部折叠</vxe-button>
//     ]
//   }
// })

// 创建一个实现Excel的筛选器
VXETable.renderer.add('FilterExcel', {
  // 不显示底部按钮，使用自定义的按钮
  isFooter: false,
  // 筛选模板
  renderFilter (h, renderOpts, params) {
    return [
      <filter-excel params={ params }></filter-excel>
    ]
  },
  // 重置数据方法
  filterResetMethod ({ options }) {
    options.forEach(option => {
      option.data = { vals: [], sVal: '', fMenu: '', f1Type: '', f1Val: '', fMode: 'and', f2Type: '', f2Val: '' }
    })
  },
  // 筛选数据方法
  filterMethod ({ option, row, column }) {
    const cellValue = XEUtils.get(row, column.property)
    const { vals, f1Type, f1Val } = option.data
    if (cellValue) {
      if (f1Type) {
        return cellValue.indexOf(f1Val) > -1
      } else if (vals.length) {
        // 通过指定值筛选
        return vals.includes(cellValue)
      }
    }
    return false
  }
})
