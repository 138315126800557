/**
 * 状态
 * @type {{UNABLE: {code: number, desc: string}, ENABLE: {code: boolean, desc: string}}}
 */
const STATE = {
  ENABLE: {
    code: 0,
    desc: '正常'
  },
  UNABLE: {
    code: 1,
    desc: '暂停'
  }
}

export default STATE
