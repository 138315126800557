import request from '@/api/request'

const ns = {
  namespaced: true,

  state: {
    nsList: [],
    nsForm: {}
  },

  getters: {
  },

  mutations: {
    SET_NS_FORM (state, value) {
      state.nsForm = value
    },
    SET_NS_LIST (state, value) {
      state.nsList = value
    }
  },

  actions: {
    getNsList ({ state, commit }) {
      request.get('/domain/GetNsGroupNames', {})
        .then(response => {
          if (response.data.status === 'ok' && response.data.data) {
            let ns = response.data.data.map(d => { return d })
            ns.push({ _id: '000000000000000000000000', name: '默认' })
            commit('SET_NS_LIST', ns)
          } else {
            commit('SET_NS_LIST', [{ _id: '000000000000000000000000', name: '默认' }])
          }
        })
    }

    // editNs ({ state, dispatch }) {
    //   request.post(`/nodes/${state.nsForm._id}`, state.nsForm)
    //     .then(() => {
    //       dispatch('getNsList')
    //     })
    // },
    // deleteNs ({ state, dispatch }, id) {
    //   request.delete(`/nodes/${id}`)
    //     .then(() => {
    //       dispatch('getNsList')
    //     })
    // }
    // getTaskList ({ state, commit }, id) {
    //   return request.get(`/nodes/${id}/tasks`)
    //     .then(response => {
    //       if (response.data.data) {
    //         commit('task/SET_TASK_LIST',
    //           response.data.data.map(d => d)
    //             .sort((a, b) => a.create_ts < b.create_ts ? 1 : -1),
    //           { root: true })
    //       }
    //     })
    // },
  }
}

// export default {
//   namespaced: true,
//   state,
//   getters,
//   mutations,
//   actions
// }

export default ns
