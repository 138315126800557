export default {
  // 内容
  addNodeInstruction: `
You cannot add nodes directly on the web interface in dns.

Adding a node is quite simple. The only thing you have to do is to run a dns service on your target machine.

#### Docker Deployment
If you are running dns using Docker, you can start a new \`worker\` container on the target machine, or add a \`worker\` service in the \`docker-compose.yml\`.

\`\`\`bash
docker run -d --restart always --name dns_worker \\
  -e DNS_SERVER_MASTER=N \\
  -e DNS_MONGO_HOST=xxx.xxx.xxx.xxx \\ # make sure you are connecting to the same MongoDB
  -e DNS_REDIS_ADDRESS=xxx.xxx.xxx.xxx \\ # make sure you are connecting to the same Redis
  server/dns:latest
\`\`\`

#### Direct Deploy
If you are deploying directly, the only thing you have to do is to run a backend service on the target machine, you can refer to [Direct Deploy].
`
}
