<template>
  <el-scrollbar wrap-class="scrollbar-wrapper">
    <div class="sidebar-logo" :class="isCollapse ? 'collapsed' : ''">
      <span></span><span v-show="!isCollapse">{{ siteName }}<span class="version">v{{version}}</span></span>
    </div>
    <el-menu
      :show-timeout="200"
      :default-active="routeLevel1"
      :collapse="isCollapse"
      background-color="#304156"
      text-color="#bfcbd9"
      active-text-color="#409EFF"
      mode="vertical"
    >
      <sidebar-item
        v-for="route in routes"
        :class="route.path.replace('/', '')"
        :key="route.path"
        :item="route"
        :base-path="route.path"
      />
    </el-menu>
  </el-scrollbar>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import SidebarItem from './SidebarItem'

export default {
  components: { SidebarItem },
  computed: {
    ...mapState('user', [
      'adminPaths'
    ]),
    ...mapGetters([
      'sidebar'
    ]),
    routeLevel1 () {
      let pathArray = this.$route.path.split('/')
      return `/${pathArray[1]}`
    },
    routes () {
      return this.$store.state.permission.routes.filter((d) => {
        return true
        // const roles = this.$store.getters['user/roles'] // const role = this.$store.getters['user/userInfo'].roles
        // // 检查用户是否具有 "admin" 角色
        // const isAdmin = roles.includes('admin')
        // if (isAdmin) return true
        // return !this.adminPaths.includes(d.path)
      })
    },
    isCollapse () {
      return !this.sidebar.opened
    },
    version () {
      return this.$store.state.version.version || window.sessionStorage.getItem('v')
    },
    siteName () {
      return this.$store.state.set.siteSet.name || window.sessionStorage.getItem('siteName')
    }
  },

  async created () {

  }
}
</script>

<style lang="scss" scoped>
#app .sidebar-container {
  width: 200px;
}

.el-menu {
  background-color: #304156;
  color: #bfcbd9;

  &::after {
    background-color: #409EFF;
  }

  .el-submenu__title {
    color: #bfcbd9;
  }

  .el-menu-item,
  .el-submenu__title {
    &:hover {
      background-color: #3a4b5d;
    }
  }

  .el-menu-item.is-active,
  .el-submenu.is-active>.el-submenu__title {
    background-color: #3a4b5d;
    color: #409EFF;

    &:hover {
      background-color: #3a4b5d;
    }
  }
}

.el-submenu__title.is-active {
  background-color: #304156;
  color: #409EFF;
}

.el-submenu__title:hover {
  background-color: #3a4b5d;
}

.sidebar-container .sidebar-logo {
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  color: #fff;
  background: #304156;
  font-size: 24px;
  font-weight: 600;
  font-family: "Verdana", serif;
}

.sidebar-container .sidebar-logo.collapsed {
  padding-left: 8px;
}

.sidebar-container .sidebar-logo .version {
  margin-left: 5px;
  font-weight: normal;
  font-size: 12px;
}
</style>
