import request from '../../api/request'

const state = {
  prodList: [],
  prodForm: {},
  prodTotal: 0
}

const getters = {}

const mutations = {
  SET_PROD_FORM (state, value) {
    state.prodForm = value
  },
  SET_PROD_LIST (state, value) {
    state.prodList = value
  },
  SET_PROD_TOTAL (state, value) {
    state.prodTotal = value
  }
}

const actions = {
  getProdList ({ state, commit }) {
    request.get('/domain/GetDomainProducts', {})
      .then(response => {
        if (response.data.status === 'ok' && response.data.data) {
          let data = response.data.data.map(d => { return d })
          data.push({ _id: '000000000000000000000000', name: '免费', nid: '000000000000000000000000', p1: 0, p2: 0, state: 0, un: 1, ut: 'ev' })
          commit('SET_PROD_LIST', data)
        } else {
          commit('SET_PROD_LIST', [{ _id: '000000000000000000000000', name: '免费', nid: '000000000000000000000000', p1: 0, p2: 0, state: 0, un: 1, ut: 'ev' }])
        }
        // commit('SET_PROD_LIST', response.data.data.list)
        // commit('SET_PROD_TOTAL', response.data.data.total)
      })
  },
  editProd ({ state, dispatch }) {
    request.post(`/product/${state.prodForm._id}`, state.prodForm)
      .then(() => {
        dispatch('getProdList')
      })
  },
  deleteProd ({ state, dispatch }, id) {
    request.delete(`/product/${id}`)
      .then(() => {
        dispatch('getProdList')
      })
  },
  getProdData ({ state, commit }, id) {
    request.get(`/product/${id}`)
      .then(response => {
        commit('SET_PROD_FORM', response.data.data)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
