import axios from 'axios'
import router from '../router'
import { Message } from 'element-ui'
import XEAjax from 'xe-ajax'

// 根据 VUE_APP_BASE_URL 生成 baseUrl
let baseUrl = process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : 'http://localhost:7000'
if (!baseUrl.match(/^https?/i)) {
  baseUrl = `${window.location.protocol}//${window.location.host}${process.env.VUE_APP_BASE_URL}`
}

// 如果 Docker 中设置了 DNS_API_ADDRESS 这个环境变量，则会将 baseUrl 覆盖
const DNS_API_ADDRESS = '###DNS_API_ADDRESS###'
if (!DNS_API_ADDRESS.match('DNS_API_ADDRESS')) {
  baseUrl = DNS_API_ADDRESS
}

XEAjax.setup({
  // 除非 url 是绝对路径（'/api/list' 或 '//test.com/api/list' 或 'https://test.com/api/list'），
  // 否则 baseURL 会拼接在 url 之前
  baseURL: baseUrl
})

// 请求之前拦截器
XEAjax.interceptors.request.use((request, next) => {
  // 用于请求的权限拦截、设置请求头、Token 验证、参数等处理...

  // 设置 Token 验证，预防 XSRF/CSRF 攻击
  request.headers.set('Authorization', `Bearer ${window.localStorage.getItem('token')}`)

  // 调用 next(),继续执行下一个拦截器
  next()
})

// 请求完成之后拦截
XEAjax.interceptors.response.use((response, next) => {
  // 请求完成之后统一处理，例如校验登录是否失效、消息提示，特殊场景处理等...

  // 例子: 判断登录失效跳转
  if (response.status === 403 || response.status === 401) {
    router.replace({ path: '/login' })
  } else if (response.status === 500) {
    // 调用 next(),继续执行下一个拦截器
    next()
  } else {
    // 调用 next(),继续执行下一个拦截器
    next()
  }
}, (e, next) => {
  // 请求发生错误
  // 调用 next(),继续执行下一个拦截器
  next()
})

// 请求完成之后改变响应结果
XEAjax.interceptors.response.use((response, next) => {
  // 例如，对所有请求结果进行处理，返回统一的数据
  response.json().then(data => {
    if (data.message === 'error') {
      Message.error(data.error)
    }
    if (data.status === 400) {
      Message.error(data.data.error)
    }
    if (data.status === 401 && router.currentRoute.path !== '/login') {
      router.replace({ path: '/login' })
      // router.push('/login')
    }
    if (data.status === 500) {
      Message.error(data.data.error)
    }
    if (data.status === 'error') {
      Message.error(data.error)
    }
    let { status, statusText, headers } = response
    let body = {
      message: status === 200 ? 'success' : 'error',
      result: data.data,
      error: data.error
    }
    // 改变响应结果并继续执行下一个拦截器
    next({ status, statusText, headers, body })
  })
}, (e, next) => {
  // 对所有请求错误返回统一的数据
  let body = {
    message: 'error',
    result: null
  }
  // 改变响应结果并继续执行下一个拦截器
  next({ status: 200, body })
})

const request = (method, path, params, data, others = {}) => {
  const url = baseUrl + path
  const headers = {
    'Authorization': `Bearer ${window.localStorage.getItem('token')}`
  }
  return axios({
    method,
    url,
    params,
    data,
    headers,
    ...others
  }).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response)
    }
    return Promise.reject(response)
  }).catch((e) => {
    let response = e.response
    if (!response) {
      return e
    }
    if (response.status === 400) {
      Message.error(response.data.error)
    }
    if (response.status === 401 && router.currentRoute.path !== '/login') {
      router.push('/login')
      return e.response
    }
    if (response.status === 405 && router.currentRoute.path !== '/login') {
      Message.error('授权失败或禁止登录')
      router.push('/login')
      return e.response
    }
    if (response.status === 500) {
      let data = response.data
      if (data === null || data === undefined) {
        Message.error('服务端返回数据为空')
        return
      }
      if (typeof data === 'string') {
        try {
          data = JSON.parse(data)
        } catch (e) {
          Message.error('服务端返回格式不对' + response.data)
          return
        }
      }
    }
    return e.response
  })
}

const get = (path, params) => {
  return request('GET', path, params)
}

const post = (path, data) => {
  return request('POST', path, {}, data)
}

const put = (path, data) => {
  return request('PUT', path, {}, data)
}

const del = (path, data) => {
  return request('DELETE', path, {}, data)
}

const XEAGet = (path, params, config) => {
  const url = baseUrl + path
  return XEAjax.get(url, params, config)
}

const XEAPost = (path, data) => {
  const url = baseUrl + path
  return XEAjax.post(url, data)
}

const XEAFetch = (path, data) => {
  const url = baseUrl + path
  return XEAjax.fetch(url, data)
}

const getWebSocketUrl = (path) => {
  const base = baseUrl || '/'
  const url = new URL(base)
  const protocol = url.protocol === 'https:' ? 'wss:' : 'ws:'
  const wsUrl = `${protocol}//${url.host}${path}`
  return wsUrl
}

export default {
  baseUrl,
  request,
  get,
  post,
  put,
  delete: del,
  XEAGet,
  XEAPost,
  XEAFetch,
  getWebSocketUrl
}
