import request from '../../api/request'

const state = {
  siteSet: {},
  systemSet: {},
  httpDnsSet: {
    enable_https: false,
    fileList: []
  },
  logClearSet: {}

}

const getters = {}

const mutations = {
  SET_SITE_SET (state, value) {
    state.siteSet = value
    sessionStorage.setItem('siteName', value.name || 'dns')
  },
  SET_SYSTEM_SET (state, value) {
    state.systemSet = value
    const enableTutorial = value.enableTutorial
    if (!localStorage.getItem('enableTutorial')) {
      localStorage.setItem('enableTutorial', enableTutorial === true ? '1' : '0')
    }
  },
  SET_SAVE_FILE_LIST (state, value) {
    state.httpDnsSet.save_file_list = value
  },
  SET_HTTPDNS_SET (state, value) {
    state.httpDnsSet = value
    if (value.save_file_list) {
      state.httpDnsSet.fileList = value.save_file_list.map((item) => {
        return { name: item.filename, response: { data: item.id } }
      })
    } else {
      // 定义一个空的 fileList 数组
      state.httpDnsSet.fileList = []
    }
  },
  SET_LOGClEAR_SET (state, value) {
    state.logClearSet = value
  }
}

const actions = {
  async saveSiteSet ({ state, dispatch }) {
    let res
    res = await request.post('/SaveSiteSet', state.siteSet)
    return res
  },
  getSystemSet ({ state, commit }) {
    return request.get('/GetSystemSet', {})
      .then(response => {
        commit('SET_SYSTEM_SET', response.data.data || {})
      })
  },
  getSiteSet ({ state, commit }) {
    return request.get('/GetSiteSet', {})
      .then(response => {
        commit('SET_SITE_SET', response.data.data || {})
      })
  },
  getHttpDnsSet ({ state, commit }) {
    return request.get('/GetHttpDnsSet', {})
      .then(response => {
        commit('SET_HTTPDNS_SET', response.data.data)
      })
  },
  async saveHttpDnsSet ({ state, dispatch }) {
    let res
    res = await request.post('/SaveHttpDnsSet', state.httpDnsSet)
    return res
  },
  async removeHttpDnsCertFile ({ state, dispatch }, id) {
    let res
    res = await request.post('/RemoveHttpDnsCertFile', id)
    return res
  },
  async SaveSystemSet ({ state, dispatch }) {
    let res
    res = await request.post('/SaveSystemSet', state.systemSet)
    return res
  },
  getLogClearSet ({ state, commit }) {
    return request.get('/GetLogClearSet', {})
      .then(response => {
        commit('SET_LOGClEAR_SET', response.data.data)
      })
  },
  async saveLogClearSet ({ state, dispatch }) {
    let res
    res = await request.post('/SaveLogClearSet', state.logClearSet)
    return res
  }

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
