import Vue from 'vue'
import Vuex from 'vuex'
import permission from './modules/permission'
import app from './modules/app'
import user from './modules/user'
import tagsView from './modules/tagsView'
import dialogView from './modules/dialogView'
import node from './modules/node'
import deploy from './modules/deploy'
import file from './modules/file'
import lang from './modules/lang'
import stats from './modules/stats'
import setting from './modules/setting'
import version from './modules/version'
import tour from './modules/tour'
import getters from './getters'

import ns from './modules/ns'
import product from './modules/product'
import group from './modules/group'
import set from './modules/set'
import regions from './modules/regions'
import domain from './modules/domain'
import dnsset from './modules/dnsset'
import batchoperations from '@/store/modules/batchoperations'
import record from './modules/record'
import monitor from './modules/monitor'
import shuffle from './modules/shuffle'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    permission,
    app,
    user,
    tagsView,
    dialogView,
    node,
    deploy,
    file,
    lang,
    setting,
    version,
    tour,
    // 统计
    stats,
    ns,
    product,
    group,
    set,
    regions,
    domain,
    record,
    dnsset,
    batchoperations,
    monitor,
    shuffle
  },
  getters
})

export default store
