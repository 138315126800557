import stats from './stats'
import encrypt from './encrypt'
import tour from './tour'
import log from './log'
// import scrapy from './scrapy'
import utils from '@/utils/utils'

export default {
  stats,
  encrypt,
  tour,
  log,
  utils
}
