import DomainExpStateTag from '@/components/Status/DomainExpStateTag.vue'

/**
 * 使用时间
 */
const ELAPSEDTIME = {
  components: { DomainExpStateTag },
  Y: {
    code: 'y',
    desc: '年'
  },
  M: {
    code: 'm',
    desc: '月'
  },
  EV: {
    code: 'ev',
    desc: '无限制'
  }
}

export default ELAPSEDTIME
