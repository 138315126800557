import Vue from 'vue'
// import './plugins'
import './plugins/utils'
import './plugins/table'
// import './plugins/filter'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets

import ElementUI from 'element-ui'

import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en' // lang i18n

import { enumParse } from '@/enums/parser' // 枚举解析工具
import enums from '@/enums' // 枚举

import '@/styles/index.scss' // global css

import 'font-awesome/scss/font-awesome.scss'// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

import 'codemirror/lib/codemirror.js'
import { codemirror } from 'vue-codemirror-lite'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/darcula.css'

import App from './App'
import store from './store'
import router from './router'

import '@/icons' // icon
import '@/permission' // permission control

import request from './api/request'
import i18n from './i18n'
import utils from './utils'

import VTooltip from 'v-tooltip'

// vue-tour
import VueTour from 'vue-tour'
import 'vue-tour/dist/vue-tour.css'

// import Antd from 'ant-design-vue'
// import 'ant-design-vue/dist/antd.css'

// 公共方法
import baseFuns from './utils/baseFuns' // 默认表格样式很丑 引入这个样式就可以好看啦（如果你不喜欢这个样式，就不要引入，不引入就跟ele表格样式一样）

import Vtip from 'vtip'
import 'vtip/lib/index.min.css'

import vcrontab from 'vcrontab'

import variables from '@/styles/variables.scss'

// Vue.use(Antd)
Vue.use(vcrontab) // 使用方式：<vcrontab></vcrontab>

Vue.use(baseFuns)

// code mirror
Vue.use(codemirror)

// element-ui
Vue.use(ElementUI, { locale })

Vue.use(enums, enumParse())

Vue.use(VTooltip)

// font-awesome
library.add(fab)
library.add(far)
library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

// vue-tour
Vue.use(VueTour)

Vue.config.productionTip = false

// 百度统计
if (localStorage.getItem('useStats') !== '0') {
  window._hmt = window._hmt || [];
  (function () {
    // let hm = document.createElement('script')
    // hm.src = 'https://hm.baidu.com/hm.js?1111111111111
    // let s = document.getElementsByTagName('script')[0]
    // s.parentNode.insertBefore(hm, s)
  })()
}

// inject request api
Vue.prototype.$request = request

// inject utils
Vue.prototype.$utils = utils
Vue.prototype.$xyutils = utils.utils

// inject stats
Vue.prototype.$st = utils.stats

// 用于显示loading
Vue.prototype.bus = new Vue()
// 注册指令使用
Vue.use(Vtip.directive)
// 工具函数调用
Vue.prototype.$tip = Vtip.tip

Vue.prototype.$variables = variables

Vue.prototype.$vh = (document.documentElement.clientHeight || document.body.clientHeight) / 100
Vue.prototype.$defaultTableHeight = Vue.prototype.$vh * 100 - 140

const app = new Vue({
  el: '#app',
  i18n,
  router,
  store,
  render: h => h(App)
})
export default app
