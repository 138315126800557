
export default {
  install (Vue) {
  // 时间格式化
    Vue.prototype.format_date = function (date, fmt) {
      var o = {
        'M+': date.getMonth() + 1, // 月份
        'd+': date.getDate(), // 日
        // eslint-disable-next-line eqeqeq
        'h+': date.getHours() % 12 == 0 ? 12 : date.getHours() % 12, // 小时
        'H+': date.getHours(), // 小时
        'm+': date.getMinutes(), // 分
        's+': date.getSeconds(), // 秒
        'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
        'S': date.getMilliseconds() // 毫秒
      }
      var week = {
        '0': '周日',
        '1': '周一',
        '2': '周二',
        '3': '周三',
        '4': '周四',
        '5': '周五',
        '6': '周六'
      }
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
      }
      if (/(E+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, ((RegExp.$1.length > 1) ? (RegExp.$1.length > 2 ? '' : '') : '') + week[date.getDay() + ''])
      }
      for (var k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
        // eslint-disable-next-line eqeqeq
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
        }
      }
      return fmt
    }

    // 获取月初，获取 当前月+gap 的月初(若gap为-1，则获取上月初，若gap为1，则获取下月初，
    Vue.prototype.get_month_first = function (gap) {
      let date1 = new Date()
      // 获取间隔月
      let gapMonth = date1.getMonth() + gap
      // 获取间隔月月初
      let gapMonthFirstDay = new Date(date1.getFullYear(), gapMonth, 1)
      let firstDay = this.format_date(gapMonthFirstDay)
      this.$utils.logInfo(firstDay)
      return firstDay
    }

    // 获取月末
    Vue.prototype.get_month_last = function (gap) {
    // gap = gap + 1
      let date1 = new Date()
      // 获取下个月月初
      let gapMonth = date1.getMonth()
      gapMonth = gapMonth + 1 + gap

      let NextMonthFirstDay = new Date(date1.getFullYear(), gapMonth, 1)
      NextMonthFirstDay = NextMonthFirstDay.getTime() - 1000 * 60 * 60 * 24
      let temp = new Date()
      temp.setTime(NextMonthFirstDay)
      let lastDay = this.format_date(temp)
      return lastDay
    }

    // 获取日期函数，若gap=0,则为系统日期，若不为0，则当期日期-gap(待参考)
    Vue.prototype.get_date = function (gap) {
      let date = new Date()
      date = date.getTime() + 1000 * 60 * 60 * 24 * gap
      let temp = new Date()
      temp.setTime(date)
      // let day = this.format_date(temp)
      return temp
    }

    // 时间戳转时间
    Vue.prototype.timestamp_to_time = function (gap) {
    // let year = new Date(now).getFullYear()
    // let month = new Date(now).getMonth() + 1
    // let date = new Date(now).getDate()
    // let hour = new Date(now).getHours()
    // let minute = new Date(now).getMinutes()
    // let second = new Date(now).getSeconds()
    // return year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second
    // alert(page)
    }
  }
}
