import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import request from '../api/request'
import stats from '../utils/stats'
import utils from '../utils/utils'

Vue.use(Router)

// 定义常规路由
const routes = [
  { path: '/login', component: () => import('../views/login/index'), hidden: true },
  { path: '/signup', component: () => import('../views/login/index'), hidden: true },
  { path: '/findpw', component: () => import('../views/login/findpw.vue'), hidden: true },
  { path: '/404', component: () => import('../views/404'), hidden: true },
  { path: '/', redirect: '/home' }
]

const router = new Router({
  // mode: 'history', // 后端支持可开
  scrollBehavior: () => ({ y: 0 }),
  routes: routes
})

router.beforeEach(async (to, from, next) => {
  if (to.meta && to.meta.title) {
    const title = sessionStorage.getItem('siteName')
    window.document.title = `${title} - ${to.meta.title}`
  } else {
    window.document.title = 'dns'
  }

  if (['/login', '/signup', '/findpw'].includes(to.path)) {
    next()
  } else {
    if (window.localStorage.getItem('token')) {
      const hasRoles = store.getters['user/roles'] && store.getters['user/roles'].length > 0
      if (hasRoles) {
        next()
      } else {
        try {
          // // 获取用户角色
          const roles = JSON.parse(window.localStorage.getItem('roles'))
          // console.log('roles', roles)
          // 生成动态路由
          const accessRoutes = await store.dispatch('permission/generateRoutes', roles)
          // 添加动态路由
          accessRoutes.forEach(route => {
            router.addRoute(route)
          })

          // 将角色存储在Vuex状态中
          store.commit('user/SET_ROLES', roles)

          next({ ...to, replace: true })
        } catch (error) {
          utils.logError('error:', error)
          // 如果发生错误，可以执行错误处理操作，如重定向到登录页面
          next('/login')
        }
      }
    } else {
      next('/login')
    }
  }
})

router.afterEach(async (to, from, next) => {
  if (to.path) {
    await store.dispatch('setting/getSetting')
    const res = await request.get('/version')
    const version = res.data.data
    store.commit('version/SET_VERSION', version)
    sessionStorage.setItem('v', version)

    await store.dispatch('set/getSiteSet')
    stats.sendPv(to.path)
  }
})

export default router
