import request from '../../api/request'

const state = {
  dnsset: {
    freqList: []
  }
}

const getters = {}

const mutations = {
  SET_DNSSET (state, value) {
    state.dnsset = value
  }
}

const actions = {
  getDnsSet ({ state, commit }) {
    request.XEAGet('/GetDnsSet', {}).then(response => {
      if (response.message === 'success') {
        let DnsSet = response.result
        DnsSet.freqList = DnsSet.freq.split(',')
        commit('SET_DNSSET', DnsSet)
      } else {
        commit('SET_DNSSET', { })
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
