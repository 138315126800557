// eslint-disable-next-line no-unused-vars
export default {
  sendPv (page) {
    if (localStorage.getItem('useStats') !== '0') {
      window._hmt.push(['_trackPageview', page])
      // sendEvCrawldu('访问页面', page, '')  用来追踪热度
    }
  },
  sendEv (category, eventName, optLabel, optValue) {
    if (localStorage.getItem('useStats') !== '0') {
      window._hmt.push(['_trackEvent', category, eventName, optLabel, optValue])
      // sendEvCrawldu(category, eventName, optLabel) 用来追踪热度
    }
  }
}
