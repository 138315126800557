import request from '../../api/request'

const state = {
  recordsList: [],
  recordsListTotalCount: 0,
  recordsForm: {
    records: []
  },

  // filter
  filter: {
    keyword: ''
  },
  // pagination
  pageNum: 1,
  pageSize: 10
}

const getters = {

}

const mutations = {
  SET_RECORDS_FORM (state, value) {
    state.recordsForm = value
  },
  SET_RECORDS_LIST (state, value) {
    state.recordsList = value
  },
  SET_PAGE_NUM (state, value) {
    state.pageNum = value
  },
  SET_PAGE_SIZE (state, value) {
    state.pageSize = value
  },
  SET_RECORDS_LIST_TOTAL_COUNT (state, value) {
    state.recordsListTotalCount = value
  },
  SET_RECORDS_DATA (state, value) {
    state.recordsForm.records = value
  }
}

const actions = {
  GetRecords ({ state, dispatch, commit }, payload) {
    const { id, domain } = payload
    return request.get(`/records/${id}`, {
      page_num: state.pageNum,
      page_size: state.pageSize,
      keyword: state.filter.keyword.trim() || undefined
    })
      .then(response => {
        if (response.data && response.data.data) {
          let data = response.data.data.list || []
          let total = response.data.data.total || 0
          data = data.map(d => {
            return {
              ...d,
              domain: domain
            }
          })
          commit('SET_RECORDS_DATA', data)
          commit('SET_RECORDS_LIST_TOTAL_COUNT', total)
        }
      })
  },
  async deleteRecord (row) {
    // 从数据库中删除该行记录
    const { id } = row
    const res = await request.delete(`/records/${id}`)

    // 从数据源中删除该行记录
    if (res.status === 200) {
      const index = state.recordsForm.records.indexOf(row)
      state.recordsForm.records.splice(index, 1)
    }
    return res
  },
  SaveRecords ({ state, dispatch, commit }, payload) {
    const { id, saveData } = payload
    return request.XEAPost(`/records/${id}`, saveData)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
