const state = {}
const getters = {
  useStats () {
    return localStorage.getItem('useStats')
  }
}
const mutations = {}
const actions = {}
export default {
  state,
  getters,
  mutations,
  actions
}
