import request from '../../api/request'
import pako from 'pako'

const state = {
  regions: []
}

const getters = {}

const mutations = {
  SET_REGIONS (state, value) {
    state.regions = value
  }
}

const actions = {
  async getRegions ({ state, commit }) {
    // 尝试从本地存储中获取缓存的数据
    const cachedData = JSON.parse(localStorage.getItem('regions'))
    if (cachedData && Date.now() - cachedData.timestamp < 5 * 60 * 1000) {
      // 如果缓存数据未过期，则直接使用缓存数据
      commit('SET_REGIONS', cachedData.data)
      return
    }

    const response = await request.XEAGet('/GetDomainRegion', {})

    if (response.message === 'success') {
      const decodedData = atob(response.result.gzip)

      const uint8ArrayData = new Uint8Array(decodedData.length)
      for (let i = 0; i < decodedData.length; i++) {
        uint8ArrayData[i] = decodedData.charCodeAt(i)
      }

      const inflatedData = pako.inflate(uint8ArrayData)

      const strData = new TextDecoder().decode(inflatedData)

      commit('SET_REGIONS', JSON.parse(strData))

      // 将新的数据和当前时间戳存储到本地存储中
      localStorage.setItem('regions', JSON.stringify({
        data: JSON.parse(strData),
        timestamp: Date.now()
      }))
    }
  }
}

// const actions = {
//   getRegions ({ state, commit }) {
//     request.XEAGet('/GetDomainRegion', {}).then(response => {
//       if (response.message === 'success') {
//         // console.log(JSON.stringify(response))
//
//         commit('SET_REGIONS', JSON.parse(response.result))
//       }
//     })
//   }
// }

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
