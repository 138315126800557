import request from '../../api/request'
import Vue from 'vue'

const state = {
  shuffleList: [],
  shuffleListTotalCount: 0,
  shuffleForm: {
    shuffleIpStr: '',
    shuffleIp: [],
    interval: 0
  },

  // filter
  filter: {
    keyword: ''
  },
  // pagination
  pageNum: 1,
  pageSize: 10
}

const getters = {}

const mutations = {
  SET_SHUFFLE_FORM (state, value) {
    // console.log(value)
    state.shuffleForm = value
  },
  SET_SHUFFLE_LIST (state, value) {
    state.shuffleList = value
  },
  SET_PAGE_NUM (state, value) {
    state.pageNum = value
  },
  SET_PAGE_SIZE (state, value) {
    state.pageSize = value
  },
  SET_SHUFFLE_LIST_TOTAL_COUNT (state, value) {
    state.shuffleListTotalCount = value
  }
}

const actions = {
  getShuffle ({ state, commit }) {
    request.XEAGet(`/GetShuffleList`, {
      page_num: state.pageNum,
      page_size: state.pageSize,
      keyword: state.keyword || undefined
    }).then(response => {
      if (response.result && response.result.list) {
        let data = response.result.list || []
        let total = response.result.total || 0

        commit('SET_SHUFFLE_LIST', data)
        commit('SET_SHUFFLE_LIST_TOTAL_COUNT', total)
      } else {
        commit('SET_SHUFFLE_LIST', [])
        commit('SET_SHUFFLE_LIST_TOTAL_COUNT', 0)
      }
    })
      .catch(error => {
        Vue.prototype.$notify({
          title: '出错了',
          message: error.message,
          type: 'error'
        })
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
