export default {
  // 菜单
  'Home': '主页',
  'Nodes': '节点',
  'Node Detail': '节点详情',
  'system': '系统管理',
  'Setting': '设置',

  // 标签
  'Overview': '概览',
  'Files': '文件',
  'Log': '日志',
  'Results': '结果',
  'Environment': '环境',
  'Analytics': '分析',
  'Rules': '规则',
  'Config': '配置',

  // 任务状态
  Pending: '待定',
  Running: '进行中',
  Finished: '已完成',
  Error: '错误',
  NA: '未知',
  Cancelled: '已取消',
  Abnormal: '异常',

  // 操作
  Add: '添加',
  Create: '创建',
  Run: '运行',
  Deploy: '部署',
  Save: '保存',
  Cancel: '取消',
  Import: '导入',
  Submit: '提交',
  'Refresh': '刷新',
  'View': '查看',
  'Edit': '编辑',
  'Remove': '删除',
  'Confirm': '确认',
  'Stop': '停止',
  'Preview': '预览',
  'Extract Fields': '提取字段',
  'Download': '下载',
  'Download CSV': '下载CSV',
  'Upload Zip File': '上传Zip文件',
  'Upload': '上传',
  'Item Threshold': '子项阈值',
  'Back': '返回',
  'New File': '新建文件',
  'Rename': '重命名',
  'Install': '安装',
  'Uninstall': '卸载',
  'Create Directory': '新建目录',
  'Create File': '新建文件',
  'Add Node': '添加节点',
  'Add Project': '添加项目',
  'Sync': '同步',
  'Auto Sync': '自动同步',
  'Sync Frequency': '同步频率',
  'Reset': '重置',
  'Copy': '复制',
  'Upgrade': '版本升级',
  'Ok': '确定',

  // 主页
  'Total Domain': '域名总数',
  'Active Nodes': '在线节点',
  'Total Deploys': '总部署数',
  'Daily New Tasks': '每日新增任务数',

  // 节点
  'Node Info': '节点信息',
  'Node Name': '节点名称',
  'Node IP': '节点IP',
  'Node MAC': '节点MAC',
  'Node Port': '节点端口',
  'Description': '描述',
  'All Nodes': '所有节点',
  'Node List': '节点列表',
  'Network': '拓扑图',
  'Node Network': '节点拓扑图',
  'Master': '主节点',
  'Worker': '工作节点',
  'Installation': '安装',
  'Search Dependencies': '搜索依赖',

  // 节点列表
  'IP': 'IP地址',
  'Port': '端口',

  // 节点状态
  Online: '在线',
  Offline: '离线',
  Unavailable: '未知',

  // ,
  'Variable': '变量',
  'Value': '值',
  'Add Environment Variables': '添加环境变量',
  'Text': '文本',
  'Attribute': '属性',
  'Field Name': '字段名称',
  'Query Type': '查询类别',
  'Query': '查询',
  'Extract Type': '提取类别',
  'CSS Selector': 'CSS选择器',
  'CSS': 'CSS',
  'XPath': 'Xpath',
  'List Only': '仅列表',
  'Detail Only': '仅详情页',
  'List + Detail': '列表＋详情页',
  'Start URL': '开始URL',
  'Item Selector': '列表项选择器',
  'Item Selector Type': '列表项选择器类别',
  'Pagination Selector': '分页选择器',
  'Pagination Selector Type': '分页项选择器类别',
  'Preview Results': '预览结果',
  'Obey robots.txt': '遵守Robots协议',
  'List Page Fields': '列表页字段',
  'Detail Page Fields': '详情页字段',
  'Detail Page URL': '详情页URL',
  'All': '全   部',
  'Stages': '阶段',
  'Process': '流程',
  'Stage Process': '流程图',
  'Stage Name': '阶段名称',
  'Start Stage': '开始阶段',
  'Engine': '引擎',
  'Selector Type': '选择器类别',
  'Selector': '选择器',
  'Is Attribute': '是否为属性',
  'Next Stage': '下一阶段',
  'No Next Stage': '没有下一阶段',
  'Fields': '字段',
  'Stage': '阶段',
  'Is List': '是否为列表',
  'List': '列表',
  'Pagination': '分页',
  'Settings': '设置',
  'Display Name': '显示名称',
  'Parameter Name': '参数名',
  'Parameter Value': '参数值',
  'Parameter Type': '参数类别',
  'Other': '其他',
  'Variable Name': '变量名',
  'Variable Type': '变量类型',
  'Variable Value': '变量值',
  'Parameter Edit': '参数编辑',
  'Has Credential': '需要验证',
  'SSH Public Key': 'SSH 公钥',
  'Is Long Task': '是否为长任务',
  'Long Task': '长任务',
  'Running Task Count': '运行中的任务数',
  'Running Tasks': '运行中的任务',
  'Item Name': 'Item 名称',
  'Add Item': '添加 Item',
  'Add Variable': '添加变量',
  'Owner': '所有者',
  'Convert to Customized': '转化为自定义',
  'Is De-Duplicated': '是否去重',
  'Please enter de-duplicated field': '请输入去重字段',
  'Overwrite': '覆盖',
  'Ignore': '忽略',
  'De-Duplication': '去重',

  // 任务列表
  'Node': '节点',
  'Create Time': '创建时间',
  'Start Time': '开始时间',
  'Finish Time': '结束时间',
  'Update Time': '更新时间',

  // 部署
  'Time': '时间',

  // 用户
  'Super Admin': '超级管理员',

  // 文件
  'Choose Folder': '选择文件',
  'File': '文件',
  'Folder': '文件夹',
  'Directory': '目录',

  // 列表
  'Name': '名称',
  'Last Run': '上次运行',
  'Action': '操作',
  'No command line': '没有执行命令',
  'Last Status': '上次运行状态',
  'Remark': '备注',

  // 搜索
  Search: '搜索',

  // 下拉框
  User: '用户',
  Logout: '退出登录',
  Documentation: '文档',

  // 变量类型
  'String': '字符串',
  'Number': '数字',
  'Boolean': '布尔值',
  'Array/List': '数组/列表',
  'Object/Dict': '对象/字典',

  // 选择
  'Yes': '是',
  'No': '否',

  // 系统
  'OS': '操作系统',
  'ARCH': '操作架构',
  'Number of CPU': 'CPU数',
  'Executables': '执行文件',
  'Latest Version': '最新版本',
  'Version': '版本',
  'Installed': '已安装',
  'Not Installed': '未安装',
  'Installing': '正在安装',
  'Install All': '安装全部',
  'Other language installing': '其他语言正在安装',
  'This language is not installed yet.': '语言还未安装',
  'Languages': '语言',
  'Dependencies': '依赖',
  'Install on All Nodes': '安装在所有节点',

  // 弹出框
  'Notification': '提示',
  'Are you sure to delete this node?': '你确定要删除该节点?',
  'Are you sure to delete this file/directory?': '你确定要删除该文件/文件夹?',
  'Are you sure to delete this task?': '您确定要删除该任务?',
  'Converted successfully': '成功转化',
  'Converted unsuccessfully': '未成功转化',
  'Node info has been saved successfully': '节点信息已成功保存',
  'A task has been scheduled successfully': '已经成功派发一个任务',
  'Are you sure to delete this user?': '你确定要删除该用户?',
  'Saved file successfully': '成功保存文件',
  'An error happened when fetching the data': '请求数据时出错',
  'Error when logging in (Please read documentation Q&A)': '登录时出错（请查看文档 Q&A）',
  'Please enter the correct username': '请输入正确用户名',
  'Password length should be no shorter than 5': '密码长度不能小于5',
  'Two passwords must be the same': '两个密码必须要一致',
  'username already exists': '用户名已存在',
  'Deleted successfully': '成功删除',
  'Saved successfully': '成功保存',
  'Renamed successfully': '重命名保存',
  'English': 'English',
  'Are you sure to delete the schedule task?': '确定删除定时任务?',
  ' is not installed, do you want to install it?': ' 还没有安装，您是否打算安装它?',
  'DownUrlResult': '下载地址结果',
  'DownUrlRealResult': '实时下载地址结果',
  'Checked result': '检测结果',
  'Please search dependencies': '请搜索依赖',
  'No Data': '暂无数据',
  'Show installed': '查看已安装',
  'Installing dependency successful': '安装依赖成功',
  'Installing dependency failed': '安装依赖失败',
  'You have successfully installed a dependency: ': '您已成功安装依赖: ',
  'The dependency installation is unsuccessful: ': '安装依赖失败: ',
  'Uninstalling dependency successful': '卸载依赖成功',
  'Uninstalling dependency failed': '卸载依赖失败',
  'You have successfully uninstalled a dependency: ': '您已成功卸载依赖: ',
  'The dependency uninstallation is unsuccessful: ': '卸载依赖失败: ',
  'Installing language successful': '安装语言成功',
  'Installing language failed': '安装语言失败',
  'You have successfully installed a language: ': '您已成功安装语言: ',
  'The language installation is unsuccessful: ': '安装语言失败: ',
  'Email format invalid': '邮箱地址格式不正确',
  'Please select a file or click the add button on the left.': '请在左侧选择一个文件或点击添加按钮.',
  'New Directory': '新建目录',
  'Enter new directory name': '输入新目录名称',
  'New directory name': '新目录名称',
  'Enter new file name': '输入新文件名称',
  'New file name': '新文件名称',
  'Release Note': '发布记录',
  'How to Upgrade': '升级方式',
  'Release': '发布',
  'Submitted successfully': '提交成功',

  // 登录
  'Sign in': '登录',
  'Sign-in': '登录',
  'Sign out': '退出登录',
  'Sign-out': '退出登录',
  'Sign up': '注册',
  'Sign-up': '注册',
  'Forgot Password': '忘记密码',
  'Has Account': '已有账号',
  'New to dns': '新用户',
  'Initial Username/Password': '初始用户名/密码',
  'Username': '用户名',
  'Password': '密码',
  'Confirm Password': '确认密码',
  'normal': '普通用户',
  'admin': '管理用户',
  'Role': '角色',
  'Edit User': '更改用户',
  'Users': '用户',
  'Email': '邮箱',
  'Optional': '可选',

  // 设置
  'Notification Trigger Timing': '消息通知触发时机',
  'On Task End': '当任务结束',
  'On Task Error': '当任务发生错误',
  'Never': '从不',
  'DingTalk Robot Webhook': '钉钉机器人 Webhook',
  'Wechat Robot Webhook': '微信机器人 Webhook',
  'Password Settings': '密码设置',
  'Notifications': '消息通知',
  'Global Variable': '全局变量',
  'Add Global Variable': '新增全局变量',
  'Are you sure to delete this global variable': '确定删除该全局变量？',
  'Key': '设置',
  'Allow Sending Statistics': '允许发送统计信息',
  'General': '通用',
  'Enable Tutorial': '启用教程',
  'Error Regex Pattern': '异常正则表达式',
  'By default: ': '默认: ',
  'Max Error Logs Display': '最大异常日志展示',
  'Log Errors': '日志错误',
  'No Expire': '不过期',
  'Log Expire Duration': '日志过期时间',

  // 时间
  'Second': '秒',
  'Seconds': '秒',
  'Minute': '分',
  'Minutes': '分',
  'Hour': '小时',
  'Hours': '小时',
  'Day': '天',
  'Days': '天',
  'Week': '周',
  'Weeks': '周',
  'Month': '月',
  'Months': '月',
  'Year': '年',
  'Years': '年',

  // 全局
  'Related Documentation': '相关文档',
  'Click to view related Documentation': '点击查看相关文档',

  'Incorrect username or password': '用户名或密码错误',
  'You are running on a mobile device, which is not optimized yet. Please try with a laptop or desktop.': '你正在使用未经优化的移动设备运行，建议使用笔记本电脑或台式机尝试。',

  // 其他
  tagsView: {
    closeOthers: '关闭其他',
    close: '关闭',
    refresh: '刷新',
    closeAll: '关闭所有'
  },
  nodeList: {
    type: '节点类型'
  },
  schedules: {
    cron: 'Cron',
    add_cron: '生成Cron',
    // Cron Format: [second] [minute] [hour] [day of month] [month] [day of week]
    cron_format: 'Cron 格式: [秒] [分] [小时] [日] [月] [周]'
  },

  // 内容
  addNodeInstruction: `
您不能在 dns 的 Web 界面直接添加节点。

添加节点的方式非常简单，您只需要在目标机器上运行一个 dns 服务就可以了。

#### Docker 部署
如果您是用 Docker 启动 dns，可以在目标机器上运行一个新的 \`worker\` 容器，或者在 \`docker-compose.yml\` 中添加 \`worker\` 服务。

\`\`\`bash
docker run -d --restart always --name dns_worker \\
  -e DNS_SERVER_MASTER=N \\
  -e DNS_MONGO_HOST=xxx.xxx.xxx.xxx \\ # 保证连接的是同一个 MongoDB
  -e DNS_REDIS_ADDRESS=xxx.xxx.xxx.xxx \\ # 保证连接的是同一个 Redis
    server/dns:latest
\`\`\`

`,

  // 教程
  'Skip': '跳过',
  'Previous': '上一步',
  'Next': '下一步',
  'Finish': '结束',
  'Click to add a file or directory<br> on the root directory.': '点击并添加一个文件<br>或文件夹',
  'You can edit, save, rename<br> and delete the selected file <br>in this box.': '在这个栏位中，您可以<br>编辑、保存、重命名、<br>删除所选择的文件',

  'Added API token successfully': '成功添加 API Token',
  'Deleted API token successfully': '成功删除 API Token',
  'Are you sure to add an API token?': '确认创建 API Token?',
  'Are you sure to delete this API token?': '确认删除该 API Token?',
  'Please enter Web Hook URL': '请输入 Web Hook URL',
  'Token copied': 'Token已复制',
  'Error copying token': '复制token出错，不能访问剪贴板',
  'date': '日期'

}
