import request from '../../api/request'

const state = {
  domainList: [],
  domainListTotalCount: 0,
  domainForm: {},

  // filter
  filter: {
    keyword: ''
  },
  // pagination
  pageNum: 1,
  pageSize: 20
}

const getters = {

}

const mutations = {
  SET_DOMAIN_FORM (state, value) {
    // console.log(value)
    state.domainForm = value
  },
  SET_DOMAIN_LIST (state, value) {
    state.domainList = value
  },
  SET_PAGE_NUM (state, value) {
    state.pageNum = value
  },
  SET_PAGE_SIZE (state, value) {
    state.pageSize = value
  },
  SET_DOMAIN_LIST_TOTAL_COUNT (state, value) {
    state.domainListTotalCount = value
  },
  SET_RECORDS_DATA (state, value) {
    state.records = value
  }
}

const actions = {
  getDomainData ({ state, dispatch, commit }, id) {
    return request.get(`/Domain/${id}`)
      .then(response => {
        if (response.data && response.data.data) {
          let data = response.data.data
          commit('SET_DOMAIN_FORM', data)
          dispatch('domain/getRecordData', data.rid, { root: true })
        }
      })
  },
  getDomainList ({ state, commit }) {
    return request.get('/domain/GetDomainList', {
      page_num: state.pageNum,
      page_size: state.pageSize,
      keyword: state.filter.keyword.trim() || undefined
    })
      .then(response => {
        if (response.data.data) {
          commit('SET_DOMAIN_LIST', response.data.data.list || [])
          commit('SET_DOMAIN_LIST_TOTAL_COUNT', response.data.data.total)
        }
      })
  },
  AddDomain ({ state, commit }) {
    return request.post(`/AddDomain`, state.domainForm)
  },
  deleteDomain ({ state, dispatch }, id) {
    return request.delete(`/domain/${id}`)
      .then(() => {
        dispatch('getDomainList')
      })
  },
  deleteDomainMultiple ({ state }, ids) {
    return request.delete(`/domains`, {
      ids: ids
    })
  },
  startDomain ({ state, dispatch }, id) {
    return request.post(`/domains/${id}/start`)
      .then(() => {
        dispatch('getDomainList')
      })
  },
  pauseDomain ({ state, dispatch }, id) {
    return new Promise(resolve => {
      request.post(`/domains/${id}/pause`)
        .then(res => {
          dispatch('getDomainData', id)
          resolve(res)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
